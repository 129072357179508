import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0 pb-9" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LightAlert = _resolveComponent("LightAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loadingRef)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Loading..."))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.branchRef)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", null, "data: " + _toDisplayString(_ctx.branchRef), 1)
                ]))
              : (_openBlock(), _createBlock(_component_LightAlert, {
                  key: 1,
                  type: 'danger',
                  icon: 'media/icons/duotune/general/gen044.svg',
                  title: _ctx.I18nUtils.translate('sea.pages.branch.branchNotFound'),
                  content: _ctx.errorRef
                }, null, 8, ["icon", "title", "content"]))
          ], 64))
    ])
  ]))
}